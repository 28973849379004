/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  box-sizing: border-box;
}

.construction-page {
  text-align: left;  /* Aligns text to the left */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

/* Header Styles */
.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.phone-number {
  display: flex;
  align-items: center; /* Align the icon and number vertically */
  font-size: 1.5rem;
  color: #d49b4d;
  display: none;
}

.phone-icon {
  width: 1.5rem; /* Set the size of the phone icon */
  height: auto;
  margin-right: 8px; /* Add some space between the icon and the phone number */
  display: none;
}

/* Main Content Styles */
.main-content {
  display: flex;
  justify-content: space-between; /* Space between left and right elements */
  align-items: flex-start;
  margin-top: 2rem;
  width: 100%; /* Ensures content spans full width */
  /*position: relative; */ /* Ensure content stays within its container */
  padding-left: 1rem; /* Reduced padding-left to move it closer to the left edge */
}

.info-section {
  max-width: 45%; /* Increased width slightly for the left content */
  margin-left: 1rem;  /* Add margin to push it slightly more left */
}

.logo img {
  max-height: 80px;  /* Adjust logo size */
  margin-bottom: 1rem;  /* Adds spacing below the logo */
}

.info-section h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.info-section p {
  font-size: 1rem;
  color: #333;
}

/* Subscribe Form Styles */
.subscribe-form {
  display: flex;
  align-items: center;
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures the button and input are contained within the border */
  background-color: #f0f0f0; /* Light background for the input */
}

.subscribe-form input {
  padding: 1rem;
  font-size: 1rem;
  border: none;
  width: 100%; /* Full width for the input */
  border-radius: 0; /* Remove input-specific border-radius */
  background-color: #EBEBEB; /* Slightly lighter than the background */
  color: #000000; /* Text color for placeholder */
  font-weight: bold; /* Bold placeholder text */
}

.subscribe-form input::placeholder {
  color: #aaa; /* Placeholder color */
  font-weight: bold; /* Bold placeholder text */
}

.subscribe-form button {
  padding: 1rem 2rem; /* Padding for the button */
  font-size: 1rem;
  background-color: #CDB089; /* Goldish background color */
  color: white;
  border: none;
  border-radius: 0; /* Remove button-specific border-radius */
  cursor: pointer;
  font-weight: bold;
  z-index: 2; /* Ensure it's above any other content */
}

/* Ensures unified border-radius for the form and no space between the input and button */
.subscribe-form input:focus,
.subscribe-form button:focus {
  outline: none; /* Remove default focus outline */
}

.Sub.info{
  font-size:  100px;
}

.privacy-statement {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  z-index: 2; /* Ensure it's above any other content */
  position: relative; /* Ensure it's above the illustration */
}

.privacy-statement a {
  color: #d49b4d;
  text-decoration: none;
}

/* Illustration aligned to the right */
.illustration {
  position: absolute; /* Take the illustration out of the normal flow */
  right: 0; /* Send it to the right edge of the page */
  top: 0; /* Align with the top of the content */
  height: 100%; /* Make sure the illustration is tall enough */
  display: flex;
  align-items: flex-start;
}

.illustration img {
  max-width: none; /* Let the image expand as needed */
  height: auto;
  max-height: 100%; /* Ensure it scales properly with the height of the page */
}

a.disable-call {
    color: inherit;
    text-decoration: none;
}

/* Responsive Styles */
/* Tablet view (screens below 768px) */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column; /* Stack the content vertically */
    align-items: center; /* Center the content */
    padding: 1rem;
  }

  .info-section {
    max-width: 100%; /* Use full width on smaller screens */
    text-align: center; /* Center-align text */
  }

  .subscribe-form {
    flex-direction: column; /* Stack the email input and subcribe button vertically */
  }

  .subscribe-form input {  
    width: 95%; /* 95 to have slight shift off box */
  }
  .subscribe-form button {
    text-align: center;
    width: 100%; /* Full width for input and button */
  }

  .illustration img{
    display: none;
  }

  input:focus::placeholder { /* Hides placeholder when interacted on tablet/mobile.*/
    color: transparent;
  }
  
  a[class=“disable-call”] {
      pointer-events: auto !important;
      cursor: auto !important;
   }
}
/* Adjust some text on smaller devices */
@media (max-width: 480px) {

  .info-section p {
    font-size: 0.9rem;
  }
  .info-section h2 {
    font-size: 1.1rem;
  }
  .phone-number {
    font-size: 0.9rem
  }
  .phone-icon {
      width: 0.9rem
  }
}

